import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'nbm-plain-button',
  templateUrl: './plain-button.component.html',
  styleUrls: ['./plain-button.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, RouterModule, NgTemplateOutlet],
})
export class PlainButtonComponent {
  @Input() processing: boolean = false;
  @Input() largeButton: boolean = false;
  @Input() routerLink: string[];
  @Input() href: string;
  @Input() additionalClasses: string;
  @Input() target: string = '_self';

  @Output()
  clicked = new EventEmitter<Boolean>();
}
