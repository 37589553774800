import { NgModule } from '@angular/core';
import { CommonModule, NgSwitch } from '@angular/common';
import { MegaMenuComponent } from './mega-menu.component';
import { RemoteContentModule } from '../../../modules/global/remote-content/remote-content.module';
import { RouterModule } from '@angular/router';
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ExpandedMenuItemsComponent } from './pages/expanded-menu-items/expanded-menu-items.component';

import { ExpandedMenuKissComponent } from './pages/expanded-menu-kiss/expanded-menu-kiss.component';
import { NavigationByYearComponent } from './components/navigation-by-year/navigation-by-year.component';
import { NavigationBySpecialityComponent } from './components/navigation-by-speciality/navigation-by-speciality.component';
import { MegaMenuParentLinkComponent } from './components/mega-menu-parent-link/mega-menu-parent-link.component';
import { LoadingModule } from '../../../modules/global/loading/loading.module';
import { CtaButtonComponent } from '../cta-button/cta-button.component';
import { ExpandedMenuBlogComponent } from './pages/expanded-menu-blog/expanded-menu-blog.component';
import { ExpandedMenuPodcastComponent } from './pages/expanded-menu-podcast/expanded-menu-podcast.component';
import { NavigationBySeasonComponent } from './components/navigation-by-season/navigation-by-season.component';
import { PlainButtonComponent } from '../plain-button/plain-button.component';
import { MegaMenuHeroComponent } from './components/mega-menu-hero/mega-menu-hero.component';
import { MegaMenuHeroPodcastComponent } from './pages/mega-menu-hero-podcast/mega-menu-hero-podcast.component';
import { MegaMenuHeroLoginComponent } from './pages/mega-menu-hero-login/mega-menu-hero-login.component';
import { ExpandedMenuWebinarsComponent } from './pages/expanded-menu-webinars/expanded-menu-webinars.component';
import { NavigationByDurationComponent } from './components/navigation-by-duration/navigation-by-duration.component';
import { ExpandedMenuOnlineCoursesComponent } from './pages/expanded-menu-online-courses/expanded-menu-online-courses.component';
import { ExpandedMenuClinicsComponent } from './pages/expanded-menu-clinics/expanded-menu-clinics.component';
import { FeaturedContentModule } from '../../../modules/shared-components/featured-content/featured-content.module';
import { ExpandedMenuBespokeCoursesComponent } from './pages/expanded-menu-bespoke-courses/expanded-menu-bespoke-courses.component';
import { ExpandedMenuCpdAdditionsComponent } from './pages/expanded-menu-cpd-additions/expanded-menu-cpd-additions.component';
import { ExpandedMenuBooksComponent } from './pages/expanded-menu-books/expanded-menu-books.component';
import { NbPlusBookOfferPromoComponent } from 'src/app/modules/shared-components/nb-plus-promos/nb-plus-book-offer-promo/nb-plus-book-offer-promo.component';

import { ExpandedMenuNbPlusComponent } from './pages/expanded-menu-nb-plus/expanded-menu-nb-plus.component';
import { ExpandedMenuAccountComponent } from './pages/expanded-menu-account/expanded-menu-account.component';
import { HeaderNavigationModule } from '../header-navigation/header-navigation.module';
import { HeaderSearchComponent } from '../header-search/header-search.component';
import { NbPlusBookOfferShortPromoComponent } from 'src/app/modules/shared-components/nb-plus-promos/nb-plus-book-offer-short-promo/nb-plus-book-offer-short-promo.component';
import { FeaturedClinicComponent } from 'src/app/modules/shared-components/featured-content/components/featured-content/featured-clinic/featured-clinic.component';
@NgModule({
  declarations: [
    MegaMenuComponent,
    ExpandedMenuItemsComponent,
    ExpandedMenuKissComponent,
    NavigationByYearComponent,
    NavigationBySpecialityComponent,
    MegaMenuParentLinkComponent,
    ExpandedMenuBlogComponent,
    ExpandedMenuPodcastComponent,
    NavigationBySeasonComponent,
    MegaMenuHeroComponent,
    MegaMenuHeroPodcastComponent,
    MegaMenuHeroLoginComponent,
    ExpandedMenuWebinarsComponent,
    NavigationByDurationComponent,
    ExpandedMenuOnlineCoursesComponent,
    ExpandedMenuClinicsComponent,
    ExpandedMenuBespokeCoursesComponent,
    ExpandedMenuCpdAdditionsComponent,
    ExpandedMenuBooksComponent,
    ExpandedMenuNbPlusComponent,
    ExpandedMenuAccountComponent,
  ],
  imports: [
    CommonModule,
    RemoteContentModule,
    RouterModule,
    NgbDropdownModule,
    NgbNavModule,
    LoadingModule,
    CtaButtonComponent,
    PlainButtonComponent,
    FeaturedContentModule,
    NbPlusBookOfferPromoComponent,
    HeaderNavigationModule,
    NgbCollapseModule,
    HeaderSearchComponent,
    NbPlusBookOfferShortPromoComponent,
  ],
  exports: [
    MegaMenuComponent,
    ExpandedMenuAccountComponent,
    ExpandedMenuCpdAdditionsComponent,
    FeaturedClinicComponent,
  ],
})
export class MegaMenuModule {}
